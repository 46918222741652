// extracted by mini-css-extract-plugin
export var services = "services-module--services--iXRY2";
export var banner = "services-module--banner--wFd5f";
export var contain = "services-module--contain--fKQQt";
export var topTit = "services-module--topTit--ga4so";
export var router = "services-module--router--BV7e3";
export var tech = "services-module--tech--2pKNi";
export var des = "services-module--des--zDN0i";
export var phoneImg = "services-module--phoneImg--pRJCe";
export var iimg = "services-module--iimg--0owip";
export var human = "services-module--human--M4bUa";
export var sales = "services-module--sales--au4iT";
export var corporate = "services-module--corporate--QG6Fl";