import React from "react"
import Layout from "../components/Layout"
import * as styles from '../styles/services.module.scss'
import { Link } from "gatsby"

import servicesBanner from '../images/services/servicesBanner.png'
import corporate from '../images/services/corporate.png'
import human from '../images/services/human.png'
import sales from '../images/services/sales.png'
import tech from '../images/services/tech.png'

import toTarget from '../js/goTarget'


import Email from '../components/email'

import Fade from 'react-reveal/Fade';
const Services = ({ location }) => {
    const anchorAnimation = (e) => {
        toTarget(e)
    }
    return (
        <Layout >
            <section className={styles.services}>
                <Email />
                <div className={styles.banner}>
                    <div className={styles.contain}>
                        <div className={styles.topTit}>
                            <Fade bottom >
                                <h2>Our Services</h2>
                            </Fade>
                            <Fade bottom delay={200}>
                                <p>We are dedicated to supporting businesses as they navigate the constantly evolving global
                                    marketplace. Our goal is to help businesses adapt and thrive in this new environment
                                    through our expert services and solutions.</p>
                            </Fade>
                            <Fade bottom delay={400}>
                                <Link to='/about#subm'>
                                    <div>Contact us</div>
                                </Link>
                            </Fade>
                        </div>
                        <div>
                            <img src={servicesBanner} alt="" />
                        </div>
                    </div>
                </div>
                <div className={styles.router}>
                    <ul>

                        <li >
                            <Link to='#Technology' onClick={anchorAnimation}>
                                <span>Technology</span>
                            </Link>
                        </li>
                        <li  >
                            <Link to='#Human' onClick={anchorAnimation}>
                                <span>Human Resources</span>
                            </Link>
                        </li>
                        <li >
                            <Link to='#Sales' onClick={anchorAnimation}>
                                <span> Sales & Marketing</span>
                            </Link>
                        </li>
                        <li >
                            <Link to='#Corporate' onClick={anchorAnimation}>
                                <span> Corporate</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.tech} id='Technology'>
                    <div className={styles.contain}>
                        <div className={styles.des}>
                            <Fade bottom delay={200}>
                                <h3>Technology</h3>
                            </Fade>
                            <div className={styles.phoneImg}>
                                <img src={tech} alt="" />
                            </div>
                            <ul>
                                <li>
                                    <span></span>Technology Architecture
                                </li>
                                <li>
                                    <span></span>Development Operations
                                </li>
                                <li>
                                    <span></span>Security and Compliance
                                </li>
                            </ul>
                            <p>
                                Today, technology intercepts virtually every business process - building dependable, modular, and futureproof technology architecture is the long game that businesses need to play.
                            </p>
                            <p>
                                Businesses frequently encounter difficulty when it comes to digital adoption. Our team understands the common pitfalls and challenges businesses encounter when implementing new technological solutions and provide ongoing support to eliminate bottlenecks and optimize system architecture.
                            </p>
                        </div>
                        <div className={styles.iimg}>
                            <img src={tech} alt="" />
                        </div>
                    </div>
                </div>

                <div className={styles.human} id='Human'>
                    <div className={styles.contain}>
                        <div className={styles.des}>
                            <Fade bottom delay={200}>
                                <h3>Human Resources</h3>
                            </Fade>
                            <div className={styles.phoneImg}>
                                <img src={human} alt="" />
                            </div>
                            <ul>
                                <li>
                                    <span></span>Resource planning
                                </li>
                                <li>
                                    <span></span>Recruitment and hiring
                                </li>
                            </ul>
                            <p>
                                Businesses are no longer geographically constrained when it comes to creating the teams they need to overcome challenges and drive growth. Resilience is adept at remote sourcing and provides a full suite of recruitment and management services fit for every business in every industry. We are also well-versed in traditional hiring and can assist in everything from talent acquisition to onboarding and team management.
                            </p>
                        </div>
                        <div className={styles.iimg}>
                            <img src={human} alt="" />
                        </div>
                    </div>
                </div>
                <div className={styles.sales} id='Sales'>
                    <div className={styles.des}>
                        <Fade bottom delay={200}>
                            <h3>Sales & Marketing</h3>
                        </Fade>
                        <div className={styles.phoneImg}>
                            <img src={sales} alt="" />
                        </div>
                        <ul>
                            <li>
                                <span></span>Sales & Marketing
                            </li>
                            <li>
                                <span></span>Lead generation
                            </li>
                            <li>
                                <span></span>Brand awareness
                            </li>
                        </ul>
                        <p>
                            Sales and marketing put business plans, hypotheses, and strategies into action. To sell more products and services, modern businesses need to precisely establish their niche, their target audience or industry, and their value proposition.
                        </p>
                        <p>
                            Resilience will laser-focus your brand positioning to maximize marketing investment and increase ROI. We will analyze your sales funnel and lead acquisition and nurturing strategies, supporting you in creating a high-conversion sales machine that drives repeat business and healthy long-standing relationships with clients and customers.
                        </p>
                    </div>
                    <div className={styles.iimg}>
                        <img src={sales} alt="" />
                    </div>
                </div>
                <div className={styles.corporate}>
                    <div className={styles.contain} id='Corporate'>
                        <div className={styles.des}>
                            <Fade bottom delay={200}>
                                <h3>Corporate</h3>
                            </Fade>
                            <div className={styles.phoneImg}>
                                <img src={corporate} alt="" />
                            </div>
                            <ul>
                                <li>
                                    <span></span>Bookkeeping
                                </li>
                                <li>
                                    <span></span>Accounting
                                </li>
                                <li>
                                    <span></span>Government funding
                                </li>
                            </ul>
                            <p>
                                Businesses need a corporate services provider that they can depend on, but also one that is quick to react to challenges and advise on fast-changing time-critical developments.
                            </p>
                            <p>
                                Resilience provides ongoing corporate support that enables businesses to streamline their use of resources, cut costs and maximize the potential of new and existing opportunities.
                            </p>
                        </div>
                        <div className={styles.iimg}>
                            <img src={corporate} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    )
}

export default Services