import React from "react"
import * as styles from '../styles/animationLoops.module.scss'
import { Link } from "gatsby"

export const Email = () => {
    return (
        <Link to="/about#video">
            <div className={styles.pageFiexd}>

            </div>
        </Link>
    )
}
 
export default Email